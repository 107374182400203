import { Skeleton } from '@/components/Skeleton';
import formatMoney from '@/helpers/util/formatMoney';
import { isDiscountAmount } from '@/helpers/util/misc';

import './Price.scss';

interface AmountProps {
  amount: number;
  currencyCode?: string;
}

export const Amount = ({ amount, currencyCode = 'USD' }: AmountProps) => formatMoney(amount, currencyCode);

const ChooseCabinPrice = ({ amount, currencyCode }: AmountProps) => {
  const originalAmountComp = <Amount amount={amount} currencyCode={currencyCode} />;
  return (
    <span className="price">
      <span className="amount">{originalAmountComp}</span>
    </span>
  );
};

interface ChooseCabinDiscountProps extends AmountProps {
  originalAmount: number;
}

const ChooseCabinDiscount = ({ amount, currencyCode, originalAmount }: ChooseCabinDiscountProps) => {
  const isDiscountPresent = isDiscountAmount(originalAmount, amount);
  const originalAmountComp = <Amount amount={originalAmount - amount} currencyCode={currencyCode} />;
  if (isDiscountPresent) {
    return (
      <span className="price price-with-discount">
        <span className="amount">{originalAmountComp}</span>
      </span>
    );
  }

  return originalAmountComp;
};

interface PriceProps extends AmountProps {
  isForceLineBreak?: boolean;
  isLoading?: boolean;
  isVariantBAndAmount?: boolean;
  isVariantBAndDiscount?: boolean;
  originalAmount?: number;
}

const Price = ({
  amount,
  currencyCode,
  isForceLineBreak = false,
  isLoading,
  isVariantBAndAmount,
  isVariantBAndDiscount,
  originalAmount = 0,
}: PriceProps) => {
  if (isLoading || isNaN(amount)) {
    return <Skeleton width="100px" />;
  }

  if (isVariantBAndAmount) {
    return <ChooseCabinPrice amount={amount} currencyCode={currencyCode} />;
  }

  if (isVariantBAndDiscount) {
    return <ChooseCabinDiscount amount={amount} currencyCode={currencyCode} originalAmount={originalAmount} />;
  }

  const isDiscountPresent = isDiscountAmount(originalAmount, amount);
  const originalAmountComp = <Amount amount={amount} currencyCode={currencyCode} />;

  if (isDiscountPresent) {
    return (
      <span className="price price-with-discount">
        <span className={`amount ${isForceLineBreak ? 'force-line-break' : 'same-line-prices'}`}>
          {originalAmountComp}
        </span>
        <span className="original-amount">
          <Amount amount={originalAmount} currencyCode={currencyCode} />
        </span>
      </span>
    );
  }

  return originalAmountComp;
};

export default Price;
