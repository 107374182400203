import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { FadeIn, Slide } from '@/components/Animations';
import Close from '@/components/Icon/Close';
import Spinner from '@/components/elements/Spinner';
import useFocusTrap from '@/ducks/a11y/hooks/useFocusTrap';
import { useModalStateFocus } from '@/ducks/a11y/hooks/useModalStateFocus';
import { clearFlyoutDataAction } from '@/ducks/flyout/actions';
import { getFlyoutData, isFlyoutLoading } from '@/ducks/flyout/selectors';
import {
  addBodyBackgroundClasses,
  clearBodyBackgroundClasses,
  getScrollbarWidth,
  isMobile,
  onBrowserBack,
} from '@/helpers/util/misc';

export const FLYOUT_ANIMATION_DELAY = 300;
let COUNT_OPENED_FLYOUTS = 0;

const Flyout = ({
  afterFlyoutChildren,
  alignButtonRight,
  ariaLabel,
  backdrop,
  children,
  className,
  closeIconId,
  contentClassName,
  directChildren,
  direction,
  disableScrollLock,
  dismissButtonOutside,
  focusOnCloseSelector,
  focusOnOpenSelector,
  fullWidth,
  hideCrossButton,
  invert,
  isCloseable,
  isDarkBackground,
  isOmitBrowserBack,
  isStickyCloseButton,
  onDismiss,
  open,
  stickyFooter,
  tabIndex,
}) => {
  const [scrollCurrentPosition, setScrollCurrentPosition] = useState(0);

  const flyoutData = useSelector(getFlyoutData);
  const isLoading = useSelector(isFlyoutLoading);
  const dispatch = useDispatch();

  const clearFlyoutData = () => {
    dispatch(clearFlyoutDataAction());
  };

  const handleLockScroll = () => {
    const state = open;
    if (state) {
      addBodyBackgroundClasses(direction, false, getScrollbarWidth());
    } else {
      releaseScroll();
    }
    if (!isOmitBrowserBack) onBrowserBack(state, closePopup);
  };

  const releaseScroll = () => {
    COUNT_OPENED_FLYOUTS--;
    if (COUNT_OPENED_FLYOUTS <= 0) {
      // let only last flyout to clear the classes
      const rootEl = document.body;
      rootEl.style.position = '';
      rootEl.style.overflow = '';
      clearBodyBackgroundClasses(direction, false);
      COUNT_OPENED_FLYOUTS = 0;
    }
  };

  const closePopup = () => {
    if (!isCloseable) return;
    const rootEl = document.body;
    if (isMobile) {
      rootEl.style.position = '';
      if (scrollCurrentPosition > 0) {
        window.scrollTo(0, scrollCurrentPosition);
      }
    }
    onDismiss();
    if (Object.keys(flyoutData).length) {
      clearFlyoutData();
    }
  };

  const [rootElement, setRootElement] = useState(null);
  const refCallback = useCallback((node) => {
    if (node && !rootElement) {
      setRootElement(node);
    }
  }, []);

  useFocusTrap({ element: rootElement, onClose: closePopup });

  // The onOpen or onClose elements may not always exist, but appear only after opening (for example, in children)
  useModalStateFocus({
    elementOnClose: focusOnCloseSelector,
    elementOnOpen: focusOnOpenSelector || !(hideCrossButton && !dismissButtonOutside),
    isOpen: open,
    rootElement,
  });

  useEffect(() => {
    if (!disableScrollLock) {
      COUNT_OPENED_FLYOUTS++;
      handleLockScroll();
    }
    return () => {
      releaseScroll();
    };
  }, [open]);

  useEffect(() => {
    setScrollCurrentPosition(Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop));
  }, [open]);

  const rootClasses = classNames({
    Flyout: true,
    [className]: !!className,
  });

  const directionClassName = direction === 'bottom-right' ? 'right' : direction;
  const contentClasses = classNames({
    [`-${directionClassName}`]: true,
    '-hasCta': stickyFooter !== null,
    Flyout__content: true,
    Flyout_width: fullWidth,
    [contentClassName]: !!contentClassName,
    darkBackground: isDarkBackground,
  });

  const backdropClasses = classNames({
    '-dark': backdrop,
    Flyout__backdrop: true,
  });

  const buttonClasses = classNames({
    '-alignButtonRight': alignButtonRight,
    '-dismissOutside': dismissButtonOutside,
    '-invert': invert,
    '-isStickyCloseButton': isStickyCloseButton,
    CloseBtn: true,
    Flyout__dismiss: true,
  });

  return (
    <div
      aria-hidden={!open}
      aria-label={ariaLabel}
      aria-modal="true"
      className={rootClasses}
      ref={refCallback}
      tabIndex={-1}
    >
      <FadeIn className={backdropClasses} in={open} mountOnEnter timeout={FLYOUT_ANIMATION_DELAY} unmountOnExit>
        <div aria-hidden onClick={closePopup} role="presentation">
          {dismissButtonOutside && (
            <button aria-label="Close" className={buttonClasses} id="close-button" type="button">
              <Close />
            </button>
          )}
        </div>
      </FadeIn>
      {directChildren && (
        <FadeIn in={open} mountOnEnter timeout={FLYOUT_ANIMATION_DELAY} unmountOnExit>
          {directChildren}
        </FadeIn>
      )}
      <Slide
        className={contentClasses}
        direction={direction}
        in={open}
        mountOnEnter
        timeout={FLYOUT_ANIMATION_DELAY}
        unmountOnExit
      >
        <div id="FlyoutParent">
          {isLoading && <Spinner />}
          {!dismissButtonOutside && !hideCrossButton && (
            <button
              aria-label="Close"
              className={buttonClasses}
              id="close-button"
              onClick={closePopup}
              tabIndex={tabIndex}
              type="button"
            >
              <Close id={closeIconId} />
            </button>
          )}
          {children}
          {stickyFooter !== null && <div className="Flyout__stickyFooter">{stickyFooter}</div>}
        </div>
      </Slide>
      {open && afterFlyoutChildren}
    </div>
  );
};

Flyout.propTypes = {
  afterFlyoutChildren: PropTypes.node,
  alignButtonRight: PropTypes.bool,
  ariaLabel: PropTypes.string,
  backdrop: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  closeIconId: PropTypes.string,
  contentClassName: PropTypes.string,
  directChildren: PropTypes.node,
  direction: PropTypes.oneOf(['left', 'modal', 'right', 'bottom-right']),
  disableScrollLock: PropTypes.bool,
  dismissButtonOutside: PropTypes.bool,
  focusOnCloseSelector: PropTypes.string,
  focusOnOpenSelector: PropTypes.string,
  fullWidth: PropTypes.bool,
  hideCrossButton: PropTypes.bool,
  invert: PropTypes.bool,
  isCloseable: PropTypes.bool,
  isDarkBackground: PropTypes.bool,
  isOmitBrowserBack: PropTypes.bool,
  isStickyCloseButton: PropTypes.bool,
  onDismiss: PropTypes.func.isRequired,
  open: PropTypes.bool,
  stickyFooter: PropTypes.node,
  tabIndex: PropTypes.string,
};

Flyout.defaultProps = {
  afterFlyoutChildren: null,
  alignButtonRight: false,
  ariaLabel: undefined,
  backdrop: true,
  className: null,
  closeIconId: 'close',
  contentClassName: null,
  directChildren: null,
  direction: 'right',
  disableScrollLock: false,
  dismissButtonOutside: false,
  fullWidth: false,
  hideCrossButton: false,
  invert: false,
  isCloseable: true,
  isDarkBackground: false,
  isOmitBrowserBack: undefined,
  isStickyCloseButton: false,
  open: false,
  stickyFooter: null,
  tabIndex: '0',
};

export default Flyout;
