import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import noop from 'lodash/noop';

import Chevron from '@/components/Icon/Chevron';

import FormControl from '../FormControl';

// TODO: Refactor this component and extract as separate components: Select, Checkbox and may be other

const propTypes = {
  autoComplete: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  getRef: PropTypes.func,
  id: PropTypes.string,
  inputmode: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  labelClassname: PropTypes.string,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  required: PropTypes.bool,
  shouldActiveStateIgnoreChildren: PropTypes.bool,
  subText: PropTypes.node,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const defaultProps = {
  autoComplete: null,
  children: null,
  className: null,
  defaultValue: null,
  disabled: null,
  error: false,
  getRef: noop,
  id: undefined,
  inputmode: 'text',
  label: null,
  labelClassname: '',
  onBlur: noop,
  onClick: noop,
  onFocus: noop,
  required: null,
  shouldActiveStateIgnoreChildren: undefined,
  subText: null,
  type: null,
  value: null,
};

/**
 * Text Field
 * @section Forms
 */

class TextField extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
      focussed: false,
      hasContent: !!(props.value || props.defaultValue),
      supportedChildren: ['option'],
    };

    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (value !== prevProps.value && !!value !== this.state.hasContent) {
      // TODO: refactor this; most likely focused and has-content styling can be handled via CSS
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        hasContent: !!value,
      });
    }
  }

  isContainer() {
    const { supportedChildren } = this.state;
    const { children } = this.props;

    return (
      children &&
      (React.isValidElement(children) ||
        (React.Children.count(children) > 1 && !supportedChildren.includes(children[0].type)))
    );
  }

  onBlur(event) {
    const { onBlur } = this.props;

    this.setState({
      expanded: false,
      focussed: false,
      hasContent: !!event.target.value,
    });

    if (onBlur) {
      onBlur(event);
    }
  }

  onClick(event) {
    const { onClick } = this.props;

    const isSelect = !!React.Children.count(this.props.children);
    this.setState({
      expanded: isSelect && !this.state.expanded,
      hasContent: !!event.target.value,
    });

    if (onClick) {
      onClick();
    }
  }

  onFocus(event) {
    const { onFocus } = this.props;

    this.setState({
      focussed: true,
      hasContent: !!event.target.value,
    });

    if (onFocus) {
      onFocus();
    }
  }

  render() {
    const { expanded, focussed, hasContent } = this.state;
    const {
      children,
      className,
      disabled,
      error,
      getRef,
      id,
      label,
      labelClassname,
      required,
      shouldActiveStateIgnoreChildren,
      subText,
      value,
    } = this.props;
    const isContainer = this.isContainer();
    const classes = classNames({
      TextField: true,
      active: hasContent || (!shouldActiveStateIgnoreChildren && !!children),
      [className]: !!className,
      disabled,
      empty: !value,
      error: !!error,
      expanded,
      focus: focussed,
      'no-label': !label,
    });

    return (
      <div className={classes}>
        {label && (
          <label className={labelClassname} htmlFor={id}>
            {label}
            {required && <span className="TextField__required">*</span>}
          </label>
        )}
        {!isContainer ? (
          <div className="TextField__input">
            <FormControl
              {...this.props}
              component={children ? 'select' : 'input'}
              getRef={getRef}
              id={id}
              native
              onBlur={this.onBlur}
              onClick={this.onClick}
              onFocus={this.onFocus}
            />
            {children && (
              <div className="TextField__caret">
                <Chevron />
              </div>
            )}
          </div>
        ) : (
          <div className="TextField__container">{children}</div>
        )}
        {subText && <span className="TextField__subText">{subText}</span>}
      </div>
    );
  }
}

TextField.propTypes = propTypes;
TextField.defaultProps = defaultProps;

export default TextField;
