import PropTypes from 'prop-types';
import React from 'react';

import spinner from '@publicImages/spinner.gif';

import './Spinner.scss';

const Spinner = ({ isOnBookingPage = false, shouldFixed = false }) => (
  <>
    {isOnBookingPage ? (
      <div
        className={shouldFixed ? 'Spinner__fixed Spinner_background' : 'Spinner__absolute Spinner_background'}
        id="Spinner"
      >
        <img alt="Loading" src={spinner} />
      </div>
    ) : (
      <div className={shouldFixed ? 'Spinner__fixed' : 'Spinner__absolute'} id="Spinner">
        <img alt="Loading" src={spinner} />
      </div>
    )}
  </>
);

Spinner.propTypes = {
  shouldFixed: PropTypes.bool,
};

Spinner.defaultProps = {
  shouldFixed: false,
};

export default Spinner;
