import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';

import Popover from '@/components/Popover';
import RefinementButtons from '@/components/ResultRefinements/RefinementButtons';
import UIResource from '@/components/UIResource';
import Button from '@/components/elements/Button';
import { FormattedMessage } from '@/helpers/formatted-message';

import './FilterPopover.scss';

export const propTypes = {
  applyNewFilterUI: PropTypes.bool,
  ariaLabel: PropTypes.string,
  bodyclass: PropTypes.string,
  boundary: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(PropTypes.element)]),
  btnClassName: PropTypes.string,
  /** Filter content */
  children: PropTypes.node.isRequired,
  /** Wrapper class */
  className: PropTypes.string,
  disabled: PropTypes.bool,
  displayReset: PropTypes.bool,
  /** Wrapper Class for Filter popover */
  filterClass: PropTypes.string,
  getMultipleCabinsNewVersion: PropTypes.func,
  headerIcon: PropTypes.shape({}),
  /** Form id */
  id: PropTypes.string,
  /** Is apply button disabled */
  isApplyDisabled: PropTypes.bool,
  label: PropTypes.string,
  newFilterDestination: PropTypes.bool,
  /** Popover dismuss handler */
  onDismiss: PropTypes.func,
  onEvent: PropTypes.func,
  /** Popover render change handler */
  onRenderChange: PropTypes.func,
  /** Filter reset function */
  onReset: PropTypes.func,
  /** Form submit function */
  onSubmit: PropTypes.func.isRequired,
  position: PropTypes.string,
  /** Additional popover icon */
  refIcon: PropTypes.node,
  /** Popover reference */
  reference: PropTypes.node.isRequired,
  renderMainFilter: PropTypes.func,
  showItineraries: PropTypes.func,
  tabIndex: PropTypes.number,
  /** Popover title */
  title: PropTypes.shape({
    defaultMessage: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  toggleAdvancedRefinement: PropTypes.func,
};

const defaultProps = {
  applyNewFilterUI: false,
  ariaLabel: null,
  bodyclass: null,
  boundary: typeof document !== 'undefined' ? document.body : null,
  btnClassName: '',
  className: null,
  disabled: false,
  displayReset: true,
  filterClass: null,
  getMultipleCabinsNewVersion: () => {},
  headerIcon: {},
  id: null,
  isApplyDisabled: false,
  label: null,
  newFilterDestination: false,
  onDismiss: noop,
  onEvent: noop,
  onRenderChange: noop,
  onReset: noop,
  position: 'bottom',
  refIcon: null,
  renderMainFilter: noop,
  showItineraries: true,
  tabIndex: 0,
  toggleAdvancedRefinement: noop,
};

const FilterPopover = ({
  applyNewFilterUI,
  ariaLabel,
  bodyclass,
  boundary,
  btnClassName,
  children,
  className,
  disabled,
  displayReset,
  filterClass,
  getMultipleCabinsNewVersion,
  headerIcon,
  id,
  isApplyDisabled,
  label,
  newFilterDestination,
  onDismiss,
  onEvent,
  onRenderChange,
  onReset,
  onSubmit,
  open,
  position,
  refIcon,
  reference,
  renderMainFilter,
  shResetDisplay,
  showItineraries,
  tabIndex,
  title,
  toggleAdvancedRefinement,
}) => {
  const Classes = classNames({
    '-newFilter': applyNewFilterUI && !newFilterDestination,
    FilterPopover: true,
    filterDestination: newFilterDestination,
  });

  const handleReset = useCallback(() => {
    onReset({ resetAll: false });
  }, [onReset]);

  /* eslint-disable react/no-unknown-property */
  return (
    <form
      aria-label={label}
      bodyclass={bodyclass}
      className={className}
      id={id}
      onSubmit={(event) => {
        event.preventDefault();
      }}
    >
      <Popover
        applyNewFilterUI={applyNewFilterUI}
        ariaLabel={ariaLabel}
        boundary={boundary}
        disabled={disabled}
        filterClass={filterClass}
        id={id}
        onDismiss={onDismiss}
        onEvent={onEvent}
        onRenderChange={onRenderChange}
        open={open}
        position={position}
        refIcon={refIcon}
        reference={reference}
        renderMainFilter={renderMainFilter}
        showItineraries={showItineraries}
        tabIndex={tabIndex}
        toggleAdvancedRefinement={toggleAdvancedRefinement}
      >
        {(closePopover, popoverContentRef) => (
          <div className={Classes} ref={popoverContentRef}>
            {displayReset && (
              <>
                <div className="FilterPopover__heading">
                  <span className="FilterPopover__title">
                    {!isEmpty(headerIcon) ? headerIcon : null}
                    <span className="FilterPopover__titleText">
                      <FormattedMessage defaultMessage={title.defaultMessage} id={title.id} />
                    </span>
                  </span>
                  {isEmpty(headerIcon) && (
                    <Button onClick={handleReset} variant="text">
                      <UIResource id="ResultRefinements.reset" />
                    </Button>
                  )}
                </div>
              </>
            )}
            {children}

            {(headerIcon || newFilterDestination) && displayReset ? (
              <div
                className={
                  newFilterDestination
                    ? 'FilterPopover__applyButton -newFilter destinationFooter'
                    : 'FilterPopover__applyButton -newFilter '
                }
              >
                <RefinementButtons
                  btnClassName={btnClassName}
                  isApplyDisabled={isApplyDisabled}
                  onApply={(event) => {
                    onSubmit(event);
                    closePopover(event);
                  }}
                  onClear={onReset}
                  shResetDisplay={shResetDisplay}
                />
              </div>
            ) : (
              <div className="FilterPopover__applyButton">
                <RefinementButtons
                  btnClassName={btnClassName}
                  isApplyDisabled={isApplyDisabled}
                  onApply={(event) => {
                    onSubmit(event);
                    closePopover(event);
                  }}
                  shResetDisplay={shResetDisplay}
                />
                {getMultipleCabinsNewVersion()}
              </div>
            )}
          </div>
        )}
      </Popover>
    </form>
  );
};

FilterPopover.propTypes = propTypes;
FilterPopover.defaultProps = defaultProps;

export default FilterPopover;
