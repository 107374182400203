import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  legend: PropTypes.string,
  role: PropTypes.string,
  tabIndex: PropTypes.number,
};

const defaultProps = {
  children: null,
  className: null,
  legend: null,
  role: null,
  tabIndex: 0,
};

const FormGroup = ({ children, className, legend, role, tabIndex }) => {
  const classes = classNames({
    [className]: !!className,
    'form-group': true,
  });

  return (
    <fieldset className={classes} role={role} tabIndex={tabIndex}>
      {legend && <legend>{legend}</legend>}
      {children}
    </fieldset>
  );
};

FormGroup.propTypes = propTypes;
FormGroup.defaultProps = defaultProps;

export default FormGroup;
