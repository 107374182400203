import React from 'react';

import cn from 'classnames';

import UIResource from '@/components/UIResource';
import Status from '@/ducks/a11y/components/Status';
import useActionStatus from '@/ducks/a11y/hooks/useActionStatus';
import useOnKeyDown from '@/hooks/useOnKeyDown';

import Button from '../elements/Button';

import './RefinementButtons.scss';

type Props = {
  applyNewFilterUI?: boolean;
  ariaLabel?: string;
  btnClassName?: string;
  isApplyDisabled?: boolean;
  onApply: () => void;
  onClear?: () => void;
  shResetDisplay?: boolean;
};

const RESET_STATUS_TEXT = 'Reset button clicked';

const RefinementButtons = ({
  applyNewFilterUI,
  ariaLabel,
  btnClassName,
  isApplyDisabled,
  onApply,
  onClear,
  shResetDisplay,
}: Props) => {
  const { actionWithStatus: resetWithStatus, closeStatus, isStatusShown } = useActionStatus(onClear);

  const onKeyDownClear = useOnKeyDown(resetWithStatus, { isDefaultPrevented: true });
  const onKeyDownApply = useOnKeyDown(onApply, { isDefaultPrevented: true });

  return (
    <div className={cn('FooterActionBar', 'RefinementButtons', applyNewFilterUI && '-newFilter')}>
      <div>
        {!shResetDisplay && (
          <Button onClick={resetWithStatus} onKeyDown={onKeyDownClear} variant="text">
            <UIResource id="ResultRefinements.clearButton.newText" />
          </Button>
        )}
      </div>
      <div>
        <Button
          aria-disabled={isApplyDisabled}
          aria-label={ariaLabel}
          className={cn(btnClassName, 'RefinementButtons__button', '-apply')}
          disabled={isApplyDisabled}
          onClick={onApply}
          onKeyDown={onKeyDownApply}
          type="submit"
          variant="primary"
        >
          <UIResource id="ResultRefinements.applyButton" />
        </Button>
      </div>

      <Status hideAfter={1000} isShown={isStatusShown} onHide={closeStatus} srOnly>
        {RESET_STATUS_TEXT}
      </Status>
    </div>
  );
};

export default RefinementButtons;
