import PropTypes from 'prop-types';
import React from 'react';

import Arrow from '@/components/Icon/Arrow';
import Chevron from '@/components/Icon/Chevron';
import Close from '@/components/Icon/Close';
import Edit from '@/components/Icon/Edit';
import { FormattedMessage } from '@/helpers/formatted-message';

import './FilterPill.scss';

export const STATES = {
  APPLIED: 'applied',
  ARROW: 'arrow',
  DEFAULT: 'default',
  DEFAULTAPPLIED: 'default-applied',
  EDIT: 'edit',
  OPEN: 'open',
  OPENAPPLIED: 'open-applied',
};

export const ARIA_LABELS = {
  [STATES.APPLIED]: 'applied',
  [STATES.ARROW]: 'arrow',
  [STATES.DEFAULT]: undefined,
  [STATES.DEFAULTAPPLIED]: 'default-applied',
  [STATES.EDIT]: 'edit',
  [STATES.OPEN]: 'open',
  [STATES.OPENAPPLIED]: 'open-applied',
};

export const VARIANTS = {
  LARGE: 'large',
  SMALL: 'small',
};

export const propTypes = {
  applyNewFilterUI: PropTypes.bool,
  disabled: PropTypes.bool,
  /** Icon component */
  icon: PropTypes.node,
  /** Label */
  label: PropTypes.node.isRequired,
  /** State (active / default / open) */
  state: PropTypes.oneOf(Object.values(STATES)),
  title: PropTypes.shape({}),
  /** Variant (large / small) */
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
};

const defaultProps = {
  applyNewFilterUI: false,
  disabled: false,
  icon: null,
  state: STATES.DEFAULT,
  title: {},
  variant: VARIANTS.SMALL,
};

// eslint-disable-next-line react/prop-types
const ControlIcon = ({ state }) => {
  switch (state) {
    case STATES.APPLIED:
      return <Close />;
    case STATES.OPENAPPLIED:
    case STATES.OPEN:
      return <Chevron position="up" />;
    case STATES.EDIT:
      return <Edit />;
    case STATES.ARROW:
      return <Arrow />;
    case STATES.DEFAULTAPPLIED:
    case STATES.DEFAULT:
    default:
      return <Chevron />;
  }
};

const FilterPill = ({ applyNewFilterUI, disabled, icon, label, state, title, variant }) =>
  !applyNewFilterUI ? (
    <span className={`filter-pill state-${state} variant-${variant} ${disabled ? 'disabled' : ''}`}>
      {variant === VARIANTS.LARGE && (
        <span className="pill-icon" disabled>
          {icon}
        </span>
      )}
      <span className="label">{label}</span>
      <span aria-label={state} className="control-icon">
        <ControlIcon state={state} />
      </span>
    </span>
  ) : (
    <span
      className={`filter-pill state-${state} variant-${variant} ${disabled ? 'disabled' : ''} ${
        applyNewFilterUI ? '-newFilter' : ''
      }`}
    >
      <div className="description">
        {(title?.id || title?.defaultMessage) && (
          <span className="title">
            <FormattedMessage defaultMessage={title.defaultMessage} id={title.id} />
          </span>
        )}
        <div className={`options -${state}`}>
          <span className={`label ${applyNewFilterUI ? '-newFilter' : ''}`}>{label}</span>
          <span aria-label={ARIA_LABELS[state]} className={`control-icon -${state}`}>
            <ControlIcon state={state} />
          </span>
        </div>
      </div>
    </span>
  );

FilterPill.propTypes = propTypes;
FilterPill.defaultProps = defaultProps;

export default FilterPill;
