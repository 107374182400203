const modifiers = {
  push: {
    enabled: true,
    fn(data, options) {
      const transform = { ...data };
      const arrowHeight = data.arrowElement.offsetHeight || 10;
      const arrowWidth = data.arrowElement.offsetWidth || 10;

      if (data.placement === 'top') {
        transform.offsets.popper.top -= options.value + arrowHeight;
      }

      if (data.placement === 'bottom' || data.placement === 'bottom-start') {
        transform.offsets.popper.top += options.value + arrowHeight;
      }

      if (data.placement === 'right') {
        transform.offsets.popper.left += options.value + arrowWidth;
      }

      if (data.placement === 'left') {
        transform.offsets.popper.left -= options.value + arrowWidth;
      }
      if (data.arrowElement.className?.includes('-newFilter')) {
        const { left } = document.getElementById('ResultRefinements__newFilterContainer').getBoundingClientRect();
        transform.offsets.popper.left = left;
      }

      return transform;
    },
    order: 800,
    value: 10,
  },
};

export default modifiers;
