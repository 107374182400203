import React, { Fragment } from 'react';

import cn from 'classnames';

import './skeleton.scss';

type StyleOptions = Partial<{
  baseColor: string;
  circle: boolean;
  direction: string;
  disableAnimation: boolean;
  duration: number | string;
  highlightColor: string;
  rounded: boolean;
}> &
  React.CSSProperties;

const styleOptionsToCssProperties = ({
  baseColor,
  circle,
  direction,
  disableAnimation,
  duration,
  highlightColor,
  rounded,
  ...rest
}: StyleOptions) => {
  const style: React.CSSProperties & Record<`--${string}`, string> = { ...rest };

  if (direction === 'rtl') style['--animation-direction'] = 'reverse';
  if (duration) style['--animation-duration'] = `${duration}s`;

  if (disableAnimation) style['--pseudo-element-display'] = 'none';

  if (circle) style.borderRadius = '50%';
  if (rounded) style.borderRadius = '4px';

  if (baseColor && baseColor !== 'undefined') style['--base-color'] = baseColor;
  if (highlightColor && highlightColor !== 'undefined') style['--highlight-color'] = highlightColor;

  return style;
};

type Props = {
  circle?: boolean;
  className?: string;

  containerClassName?: string;
  containerTestId?: string;
  count?: number;
  inDarkBg?: boolean;
  inline?: boolean;
  wrapper?: React.ComponentType<{ children: React.ReactNode }>;
} & StyleOptions;

export const Skeleton = ({
  circle = false,
  className: customClassName,
  containerClassName,
  containerTestId,
  count = 1,
  inDarkBg,
  inline = false,
  wrapper: Wrapper,
  ...propsStyleOptions
}: Props) => {
  const styleOptions = {
    ...propsStyleOptions,
    circle,
    inDarkBg,
  };

  const style = styleOptionsToCssProperties(styleOptions);

  const className = cn('loading-skeleton', inDarkBg, customClassName);

  const elements = [];
  const countCeil = Math.ceil(count);

  for (let i = 0; i < countCeil; i++) {
    let thisStyle = style;

    if (countCeil > count && i === countCeil - 1) {
      // count is not an integer, and we've reached the last iteration of
      // the loop, so add a "fractional" skeleton.

      const width = thisStyle.width ?? '100%';

      const fractionalPart = count % 1;

      const fractionalWidth = typeof width === 'number' ? width * fractionalPart : `calc(${width} * ${fractionalPart})`;

      thisStyle = { ...thisStyle, width: fractionalWidth };
    }

    const skeletonSpan = (
      <span className={className} key={i} style={thisStyle}>
        &zwnj;
      </span>
    );

    if (inline) {
      elements.push(skeletonSpan);
    } else {
      elements.push(
        <Fragment key={i}>
          {skeletonSpan}
          <br />
        </Fragment>,
      );
    }
  }

  return (
    <span className={containerClassName} data-testid={containerTestId}>
      {Wrapper ? elements.map((el) => <Wrapper key={el + 'key'}>{el}</Wrapper>) : elements}
    </span>
  );
};
